<template>
  <section class="container-fluid bg-white p-5 rounded-lg">
    <div class="d-flex">
      <p class="text-secondary my-auto mr-auto">
        <i :class="$route.meta.iconClass"></i>
        <span style="font-size: 22px; color: #7f7f7f" class="font-weight-light">
          Fiche composant technique :
        </span>
        <span style="font-size: 25px" class="font-weight-bold">{{
          COMPOSANT.name
        }}</span>
      </p>
      <div class="row mb-3">
        <div class="col-12 px-0">
          <modal-actions
            @close="
              $router.go($store.getters['routerHistory/fromCreation'] ? -2 : -1)
            "
          />
        </div>
      </div>
    </div>

    <div id="add-page" class="d-flex col-12 p-0 m-0" style="gap: 5px">
      <div class="col-md-4 shdow col-12 m-0">
        <div class="d-flex justify-content-between align-items-center">
          <p class="h4 text-secondary my-auto mr-auto">
            Informations générales
          </p>
          <button
            v-if="(!editMode || editing !== 'info') && !disabled"
            :disabled="editMode && editing !== 'info'"
            class="p-0 bg-white border-0"
            style="font-size: 30px"
            :style="{
              color: editMode && editing !== 'info' ? 'gray' : '#007bff',
            }"
            @click.prevent="(editMode = !editMode), (editing = 'info')"
          >
            <i class="mdi mdi-file-document-edit"></i>
          </button>
          <div v-else-if="!disabled" class="m-0 d-flex p-0">
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              size="sm"
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="cancelEdit"
              pill
              size="sm"
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <hr class="w-100 mt-1 mb-2 bg-secondary" />
        <div class="row p-0 m-0" v-if="!editMode || editing !== 'info'">
          <div class="col-12" style="flex: 1; margin-right: 20px">
            <div class="row">
              <div class="col-12 p-0">
                <p>
                  <span>Libellé : </span>
                  <span class="font-weight-bold">{{ COMPOSANT.name }}</span>
                </p>

                <p>
                  <span>Editeur : </span
                  ><span v-if="COMPOSANT.editeur" class="font-weight-bold">
                    {{ COMPOSANT.editeur.name || "-" }}</span
                  >
                </p>
                <p>
                  <span>Statut : </span>
                  <span class="font-weight-bold"> {{ COMPOSANT.status }}</span>
                </p>
                <p>
                  <span>Type composant : </span
                  ><span class="font-weight-bold"> {{ COMPOSANT.type }}</span>
                </p>
              </div>
              <div class="col-12 p-0">
                <p>
                  <span class="d-flex">
                    <span>Taux remplissage :</span>

                    <b-progress
                      class="col-md-6 ml-1 p-0 m-0 my-auto"
                      max="100"
                      show-progress
                      height="15px"
                    >
                      <b-progress-bar
                        :value="tauxRemplissage"
                        :label="`${tauxRemplissage}%`"
                        :style="`background-color: ${
                          tauxRemplissage > 20 && tauxRemplissage <= 70
                            ? '#f5f245'
                            : tauxRemplissage > 70
                            ? '#23d92c'
                            : '#f5ba45'
                        }`"
                      ></b-progress-bar>
                    </b-progress>
                  </span>
                </p>
                <p>
                  <span>Ligne metier : </span>
                  <span v-if="COMPOSANT.businessLine" class="font-weight-bold">
                    {{ COMPOSANT.businessLine.length }}</span
                  >
                </p>

                <p>
                  <span>Patrimoine : </span>
                  <span v-if="COMPOSANT.patrimoine" class="font-weight-bold">
                    {{ COMPOSANT.patrimoine.name || "-" }}</span
                  >
                  <span v-else> - </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else>
          <!-- First Col -->
          <div class="col-12">
            <b-form-group
              label="Libellé:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <b-form-input
                v-model="newComposant.name"
                placeholder="Libellé"
                :class="{
                  'is-invalid':
                    $v.newComposant.name.$error && $v.newComposant.name.$dirty,
                }"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.name.$dirty"
              >
                {{
                  !$v.newComposant.name.required
                    ? "Champ obligatoire"
                    : !$v.newComposant.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newComposant.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->

            <b-form-group
              label="Editeur:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.editeur.$error &&
                    $v.newComposant.editeur.$dirty,
                }"
              >
                <v-select
                  label="name"
                  class="bg-transparent"
                  :reduce="(user) => user.id"
                  :options="editerusList"
                  placeholder="Editeur"
                  v-model="newComposant.editeur"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.editeur.$dirty"
              >
                {{
                  !$v.newComposant.editeur.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->

            <b-form-group
              label="Statut:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.status.$error &&
                    $v.newComposant.status.$dirty,
                }"
              >
                <v-select
                  placeholder="Statut"
                  class="bg-transparent"
                  v-model="newComposant.status"
                  :options="['Propose', 'Non valide']"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.status.$dirty"
              >
                {{
                  !$v.newComposant.status.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <!--  -->
            <b-form-group
              label="Type:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.type.$error && $v.newComposant.type.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  placeholder="Type composant"
                  v-model="newComposant.type"
                  :options="composantTypes"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.type.$dirty"
              >
                {{ !$v.newComposant.type.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>

            <div class="row mt-1">
              <div class="col-md-4">
                <span class="font-weight-bold">Taux de remplissage:</span>
              </div>
              <div class="col-md-8 pl-2">
                <b-progress
                  class="p-0 m-0 my-auto"
                  max="100"
                  show-progress
                  height="15px"
                >
                  <b-progress-bar
                    :value="tauxRemplissage"
                    :label="`${tauxRemplissage}%`"
                    :style="`background-color: ${
                      tauxRemplissage > 20 && tauxRemplissage <= 70
                        ? '#f5f245'
                        : tauxRemplissage > 70
                        ? '#23d92c'
                        : '#f5ba45'
                    }`"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>

            <b-form-group
              label="Ligne métier:"
              class="font-weight-bold m-0"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div class="font-weight-normal p-0">
                <multiselect
                  multiple
                  label="name"
                  track-by="id"
                  v-model="newComposant.businessLine"
                  :options="linesList"
                  placeholder="Ligne métier"
                ></multiselect>
              </div>
            </b-form-group>

            <b-form-group
              label="Portée composant:"
              class="font-weight-bold m-0"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.newComposant.porte.$error &&
                    $v.newComposant.porte.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  placeholder="Portée composant"
                  :options="['Local']"
                  v-model="newComposant.porte"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.porte.$dirty"
              >
                {{ !$v.newComposant.porte.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>

            <b-form-group
              label="Patrimoine:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
              >
                <v-select
                  class="bg-transparent"
                  placeholder="Patrimoine"
                  v-model="newComposant.patrimoine"
                  label="text"
                  :reduce="(patrimoine) => patrimoine.value"
                  :options="patrimoinesList"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <!-- ERROR MESSAGES -->
              <!-- <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComposant.patrimoine.$dirty"
              >
                {{ !$v.newComposant.patrimoine.required ? "champ obligatoire" : "" }}
              </span> -->
              <!--  -->
            </b-form-group>
          </div>

          <!-- Third col -->
        </div>
      </div>
      <!--  Doucuments  -->
      <div class="col-md-4 shdow col-12 m-0">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h4 text-secondary my-auto mr-auto">Documents</p>
          <button
            v-if="(!editMode || editing !== 'document') && !disabled"
            :disabled="editMode && editing !== 'document'"
            class="p-0 bg-white border-0"
            style="font-size: 30px"
            :style="{
              color: editMode && editing !== 'document' ? 'gray' : '#007bff',
            }"
            @click.prevent="(editMode = !editMode), (editing = 'document')"
          >
            <i class="mdi mdi-file-document-edit"></i>
          </button>
          <div v-else-if="!disabled" class="m-0 d-flex p-0">
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              size="sm"
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="cancelEdit"
              pill
              size="sm"
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <hr class="w-100 mt-1 mb-2 bg-secondary" />
        <div class="row p-0 m-0" v-if="!editMode || editing !== 'document'">
          <div class="col-12 p-0">
            <div class="row">
              <div class="col-12">
                <div
                  class="text-center text-gray col-12 p-1"
                  v-if="
                    !COMPOSANT.fileComposants ||
                    !COMPOSANT.fileComposants.length
                  "
                >
                  Aucun document
                </div>
                <b-list-group v-else class="mt-3">
                  <b-list-group-item
                    v-for="(livrable, index) in COMPOSANT.fileComposants"
                    :key="index"
                    class="d-flex"
                    ><i
                      class="fa my-auto pr-1 fa-paperclip"
                      aria-hidden="true"
                    ></i>
                    <a
                      target="_blank"
                      :href="IMG_URL + livrable.fileUrl"
                      class="text-truncate"
                    >
                      {{ livrable.filename }}</a
                    >
                    <i
                      v-if="editMode && editing == 'livrable'"
                      @click="deleteLivrable(livrable.id)"
                      class="fa my-auto fa-times text-danger ml-auto"
                      aria-hidden="true"
                      style="cursor: pointer"
                    ></i>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="col-12 m-0 p-0">
            <FilePicker
              @change="filePickerChanger"
              class="font-weight-normal"
            />
            <span style="color: black" class="font-weight-bold">
              <b-list-group class="mt-3">
                <b-list-group-item
                  v-for="(livrable, index) in COMPOSANT.fileComposants"
                  :key="index"
                  class="d-flex m-0"
                  ><i
                    class="fa my-auto pr-1 fa-paperclip"
                    aria-hidden="true"
                  ></i>
                  <a class="text-truncate" :href="IMG_URL + livrable.fileUrl">
                    {{ livrable.filename }}</a
                  >
                  <i
                    @click="deleteLivrable(livrable.id)"
                    class="fa my-auto fa-times text-danger ml-auto"
                    aria-hidden="true"
                    style="cursor: pointer"
                  ></i
                ></b-list-group-item>
              </b-list-group>
            </span>
          </div>
          <!-- Couverture end -->
        </div>
      </div>
      <!-- couvertures-->
      <div class="col-md-4 shdow col-12 m-0">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h4 text-secondary my-auto mr-auto">Couvertures</p>
          <button
            v-if="(!editMode || editing !== 'couverture') && !disabled"
            :disabled="editMode && editing !== 'couverture'"
            class="p-0 bg-white border-0"
            style="font-size: 30px"
            :style="{
              color: editMode && editing !== 'couverture' ? 'gray' : '#007bff',
            }"
            @click.prevent="(editMode = !editMode), (editing = 'couverture')"
          >
            <i class="mdi mdi-file-document-edit"></i>
          </button>
          <div v-else-if="!disabled" class="m-0 d-flex p-0">
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              size="sm"
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="cancelEdit"
              pill
              size="sm"
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <hr class="w-100 mt-1 mb-2 bg-secondary" />
        <div class="row p-0 m-0" v-if="!editMode || editing !== 'couverture'">
          <div class="col-12 p-0">
            <div
              class="text-center text-gray col-12 p-1"
              v-if="!couvertures.length"
            >
              Aucune couverture
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row m-0 p-0" style="gap: 5px">
                  <div
                    style="height: 50px; position: relative; overflow: visible"
                    class="shdow"
                    v-for="(covert, index) in COMPOSANT.couvertures"
                    :key="index"
                  >
                    <p class="my-auto">
                      <country-flag
                        :country="covert.countryCode"
                        size="small"
                      />{{ covert.country || "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="" v-else>
          <div class="col-12 p-0">
            <div class="m-0 p-0">
              <Places :testCouverture="sendingFunction" />
            </div>
            <div class="row m-0 p-0">
              <div
                style="height: 50px; position: relative; overflow: visible"
                class="shdow"
                v-for="(covert, index) in couvertures"
                :key="index"
              >
                <p>
                  <country-flag
                    :country="covert.countryCode"
                    size="small"
                    v-show="covert.countryCode"
                  />{{ covert.country }}
                </p>

                <div style="position: absolute; top: -5px; right: -5px">
                  <span style="cursor: pointer"
                    ><i
                      @click="deleteCouverture(index, covert.id)"
                      class="mdi mdi-close-circle text-danger"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <!-- Couverture end -->
        </div>
        <div class="d-flex mt-3 justify-content-between align-items-center">
          <p class="h4 text-secondary my-auto mr-auto">Pays responsables</p>
          <button
            v-if="(!editMode || editing !== 'pay') && !disabled"
            :disabled="editMode && editing !== 'pay'"
            class="p-0 bg-white border-0"
            style="font-size: 30px"
            :style="{
              color: editMode && editing !== 'pay' ? 'gray' : '#007bff',
            }"
            @click.prevent="(editMode = !editMode), (editing = 'pay')"
          >
            <i class="mdi mdi-file-document-edit"></i>
          </button>
          <div v-else-if="!disabled" class="m-0 d-flex p-0">
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              size="sm"
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="cancelEdit"
              pill
              size="sm"
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <hr class="w-100 mt-1 mb-2 bg-secondary" />
        <div class="mt-3" v-if="!editMode || editing !== 'pay'">
          <div class="row m-0 p-0">
            <div
              class="text-center text-gray col-12 p-1"
              v-if="!paysResponable.length"
            >
              Aucun pays responsable
            </div>
            <div
              v-else
              style="height: 50px; position: relative; overflow: visible"
              class="shdow"
              v-for="(covert, index) in paysResponable"
              :key="index"
            >
              <p>
                <country-flag
                  :country="covert.countryCode"
                  size="small"
                  v-show="covert.countryCode"
                />{{ covert.country }}
              </p>

              <!-- <div style="position: absolute; top: -5px; right: -5px">
                <span style="cursor: pointer"
                  ><i
                    @click="deletePaysResponsable(index)"
                    class="mdi mdi-close-circle text-danger"
                  ></i
                ></span>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-12 p-0" v-else>
          <PaysResponsable :paysResponsableFunction="paysResponsableFunction" />
          <div class="row m-0 p-0">
            <div
              style="height: 50px; position: relative; overflow: visible"
              class="shdow"
              v-for="(covert, index) in paysResponable"
              :key="index"
            >
              <p>
                <country-flag
                  :country="covert.countryCode"
                  size="small"
                  v-show="covert.countryCode"
                />{{ covert.country }}
              </p>

              <div style="position: absolute; top: -5px; right: -5px">
                <span style="cursor: pointer"
                  ><i
                    @click="deletePaysResponsable(index, covert.id)"
                    class="mdi mdi-close-circle text-danger"
                  ></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <hr />
    <b-tabs
      :value="$store.state.tabs.activeTab"
      @changed="active = $store.state.tabs.activeTab"
      @activate-tab="
        (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
      "
      class="tab-solid tab-solid-primary mt-3"
    >
      <b-tab title="Référents">
        <Referent
          :referents="COMPOSANT.referents"
          :libelle="COMPOSANT.name"
          :responsables="usersList"
          :disabled="disabled"
        />
      </b-tab>

      <b-tab title="Version">
        <VersionTable :disabled="disabled" />
      </b-tab>
      <b-tab title="Capacités">
        <CapacitesTable :disabled="disabled" />
      </b-tab>
      <b-tab title="Les applications supportées">
        <ApplicationTable
          :disabled="disabled"
          :applications="COMPOSANT.applications"
        />
      </b-tab>
      <b-tab title="Transformation">
        <TrnsTable :disabled="disabled" />
      </b-tab>
      <b-tab title="Cadres rattachés"
        ><CadresTable :disabled="disabled" /></b-tab
    ></b-tabs>
  </section>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";

import VersionTable from "./components/VesionTable.vue";
import ApplicationTable from "./components/ApplicationTable.vue";
import CapacitesTable from "./components/CapacitesTable.vue";
import Referent from "./components/Referent.vue";
import TrnsTable from "./components/TransformationTable.vue";
// import SimpleSlider from "@/components/forms/sliders/simpleSlider.vue";
import CountryFlag from "vue-country-flag";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
// import VueSlideBar from "vue-slide-bar";
import FilePicker from "@/components/custom/FilePicker.vue";
import Places from "@/components/custom/Places.vue";
import PaysResponsable from "@/components/custom/PaysResponsable.vue";
import CadresTable from "./components/CadresTable";

import { USER_URL } from "@/helpers/constURL";
import { IMG_URL } from "@/helpers/services/api";
export default {
  components: {
    ModalActions,
    VersionTable,
    ApplicationTable,
    TrnsTable,
    // VueSlideBar,
    CountryFlag,
    Referent,
    Places,
    CapacitesTable,
    FilePicker,
    PaysResponsable,
    CadresTable,
  },
  data: () => ({
    IMG_URL,
    showApps: true,
    editMode: false,
    submitingForm: false,
    editing: "",
    newComposant: {
      name: "",
      type: "",
      taux: 0,
      status: "",
      // capaciteTechnique: "",
      // ligne: "",
      editeur: "",
      porte: "",
      // archictecteTechnique: "",

      patrimoine: "",
    },
    deletedCouvertures: [],
    couvertures: [],
    livrables: [],
    paysResponable: [],
    deletedPays: [],
  }),
  validations: {
    newComposant: {
      name: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      status: {
        required,
      },
      // archictecteTechnique: {
      //   required,
      // },
      // capaciteTechnique: {
      //   required,
      // },
      editeur: {
        required,
      },
      porte: {
        required,
      },
    },
  },
  methods: {
    paysResponsableFunction(location) {
      (!this.paysResponable.find(
        (pays) => pays.countryCode === location.countryCode
      ) &&
        this.paysResponable.push(this.clone(location))) ||
        Swal.fire("Impossible d'ajouter un pays déjà existant !", "", "error");
    },
    filePickerChanger(files) {
      this.livrables = files;
    },
    deleteCouverture(index, id) {
      if (id) this.deletedCouvertures.push(id);
      this.couvertures.splice(index, 1);
    },
    deletePaysResponsable(index, id) {
      if (id) this.deletedPays.push(id);
      this.paysResponable.splice(index, 1);
    },
    changeCouverture(couvertures) {
      this.couvertures = couvertures;
    },
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      if (
        !this.couvertures.find(
          (couverture) => couverture.countryCode === location.countryCode
        )
      )
        this.couvertures.push(this.clone(location));
      else
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        (this.submitingForm = true),
          this.deletedCouvertures.forEach((id) => {
            this.$store.dispatch("couverture/deleteCouverture", id).then(() => {
              this.$store.dispatch(
                "application/fetchApplication",
                this.$route.params.id
              );
            });
          });
        this.deletedCouvertures = [];
        this.$store
          .dispatch("composant/updateComposant", {
            ...this.newComposant,
            businessLine: this.newComposant.businessLine.map(
              (line) => line["@id"]
            ),
            editeur: this.newComposant.editeur["@id"],
            taux: parseInt(this.tauxRemplissage),
            couvertures: this.couvertures.map((element) => ({ ...element })),
            couvertureResps: this.paysResponable.map((element) => ({
              ...element,
            })),
            archictecteTechnique: this.newComposant.archictecteTechnique["@id"],
          })
          .then(() => {
            this.livrables.map((file, index) => {
              file.append("composant", this.COMPOSANT["@id"]);
              this.$store
                .dispatch("composant/createFileComposant", file)
                .then(() => {
                  if (this.livrables.length - 1 === index) {
                    this.$store.dispatch(
                      "composant/fetchComposant",
                      this.$route.params.id
                    );
                    this.livrables = [];
                  }
                });
            });
            this.$store
              .dispatch("composant/fetchComposant", this.$route.params.id)
              .then(() => {
                Swal.fire("Le composant est mis à jour !", "", "success");
                this.submitingForm = false;
                this.editMode = false;
              });
          });
      }
    },
    deleteLivrable(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("composant/deleteFileComposant", id).then(() => {
            this.$store
              .dispatch("composant/fetchComposant", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    cancelEdit() {
      this.$store
        .dispatch("composant/fetchComposant", this.$route.params.id)
        .then(() => {
          this.editMode = false;
          this.deletedCouvertures = [];
        });
    },
  },
  created() {
    this.$store
      .dispatch("composant/fetchComposant", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("businessLine/fetchAllBusinessLines");
    this.$store.dispatch("editeur/fetchAllEditeurs");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
  },
  computed: {
    ...mapGetters("composant", ["COMPOSANT"]),
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("businessLine", ["BUSINESS_LINES"]),
    ...mapGetters("composant", ["COMPOSANT"]),
    ...mapGetters("editeur", ["EDITEURS"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("role", ["CONTRIBUTOR_ROLES"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),

    disabled() {
      return !(this.isArchitect || this.isSuper || this.isAdmin);
    },

    capacitesList() {
      return this.CAPACITES_TECHNIQUES;
    },
    usersList() {
      return this.RESPONSABLES.map((user) => ({
        ...user,
        text: `${user.firstname} ${user.lastname}`,
        value: `/api/${USER_URL}/${user.id}`,
      }));
    },
    linesList() {
      return this.BUSINESS_LINES;
    },
    editerusList() {
      return this.EDITEURS.map((editeur) => ({
        ...editeur,
        id: editeur["@id"],
      }));
    },
    patrimoinesList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        text: patrimoine.name,
        value: patrimoine["@id"],
      }));
    },
    composantTypes() {
      return [
        "BDD",
        "Framework logiciel",
        "Hébergement",
        "Réseau & Sécurité",
        "Système d’échange",
        "Autre",
      ];
    },
    tauxRemplissage() {
      let i = 0;

      if (this.newComposant.name) i++;

      if (this.newComposant.type) i++;

      if (this.newComposant.status) i++;
      // if(this.newComposant.capaciteTechnique) i++

      if (
        this.newComposant.businessLine &&
        this.newComposant.businessLine.length
      )
        i++;

      if (this.newComposant.editeur) i++;

      if (this.newComposant.porte) i++;

      if (this.newComposant.archictecteTechnique) i++;

      if (this.newComposant.patrimoine) i++;

      if (this.couvertures.length) i++;

      if (this.livrables.length) i++;

      if (this.paysResponable.length) i++;

      if (this.COMPOSANT.versions && this.COMPOSANT.versions.length) i++;

      if (this.COMPOSANT.capacites && this.COMPOSANT.capacites.length) i++;

      if (this.COMPOSANT.referents && this.COMPOSANT.referents.length)
        if (this.COMPOSANT.referents.length >= this.CONTRIBUTOR_ROLES.length)
          i = i + this.CONTRIBUTOR_ROLES.length;
        else i = i + this.COMPOSANT.referents.length;

      if (this.COMPOSANT.applications && this.COMPOSANT.applications.length)
        i++;

      if (this.COMPOSANT.cadres && this.COMPOSANT.cadres.length) i++;

      return ((i / (15 + this.CONTRIBUTOR_ROLES.length)) * 100).toFixed(2);
    },
  },
  watch: {
    COMPOSANT: function () {
      this.newComposant = {
        patrimoine: this.COMPOSANT.patrimoine
          ? this.COMPOSANT.patrimoine["@id"]
          : null,
        id: this.COMPOSANT.id,
        name: this.COMPOSANT.name,
        editeur: this.COMPOSANT.editeur,
        porte: this.COMPOSANT.porte,
        status: this.COMPOSANT.status,
        taux: this.COMPOSANT.taux,
        type: this.COMPOSANT.type,
        businessLine: this.COMPOSANT.businessLine,
        archictecteTechnique: this.COMPOSANT.archictecteTechnique,
      };
      this.couvertures = this.COMPOSANT.couvertures;
      this.paysResponable = this.COMPOSANT.couvertureResps;
    },
  },
};
</script>

<style>
.spn {
  color: #363535;
  font-weight: bolder;
}
</style>
